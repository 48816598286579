// Hooks
import useUser from '@/hooks/swr/useUser';
import useLanguage from '@/hooks/language/useLanguage';
import useInitializeProfilesStore from '@/hooks/user/useCurrentSession/useInitializeProfilesStore';
import useCheckAndRedirect from '@/hooks/user/useCurrentSession/useCheckAndRedirect';

type UseCurrentSessionProps = {
    isLoggedRedirect?: boolean;
};

const useCurrentSession = ({ isLoggedRedirect = false }: UseCurrentSessionProps = {}) => {
    // Custom hooks
    const { user, isLoading: isUserLoading } = useUser();
    const { isInitialProfilesLoading } = useInitializeProfilesStore({ isUserLoading });
    const { isCheckRedirectLoading } = useCheckAndRedirect({
        launchCheckRedirect: !isUserLoading && !isInitialProfilesLoading,
        isLoggedRedirect
    });

    // Initialize language platform
    const { useSetLanguage } = useLanguage();
    useSetLanguage();

    return {
        user,
        isLoading: (!isLoggedRedirect && !user) || isCheckRedirectLoading
    };
};

export default useCurrentSession;
