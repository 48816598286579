import { useEffect, useState } from 'react';
import { useParams, usePathname, useRouter, useSearchParams } from 'next/navigation';

// Lib
import * as publicRoutes from '@/lib/routes/publicRoutes';

// Utils
import useUser from '@/utils/hooks/swr/useUser';
import useUrlUserHomepage from '@/hooks/user/useUrlUserHomepage';
import useSignUpCheck from '@/utils/hooks/user/useCurrentSession/useCheckAndRedirect/useSignUpCheck';
import { formatUrls } from '@/utils/urls/formatUrl';

type UseCheckAndRedirectProps = { launchCheckRedirect: boolean; isLoggedRedirect: boolean };

function useCheckAndRedirect({ launchCheckRedirect, isLoggedRedirect }: UseCheckAndRedirectProps) {
    // State loader
    const [isCheckRedirectLoading, setIsCheckRedirectLoading] = useState(true);

    // Hooks
    const { user } = useUser();
    const { userHomepage } = useUrlUserHomepage();
    const { checkAndRedirectSignUp } = useSignUpCheck();

    // Router
    const router = useRouter();
    const params = useParams();
    const pathname = usePathname();
    const searchParams = useSearchParams();

    useEffect(() => {
        if (!launchCheckRedirect) {
            return;
        }

        /**
         * If user is not logged in and the current page is not a public page, then show the loading screen
         */
        const publicPages = Object.values(publicRoutes);
        const formattedPublicUrls = formatUrls(publicPages, params);
        if (!user && !formattedPublicUrls.find((url) => url === pathname)) {
            // The user will be redirect via the SWRConfigWrapper component
            setIsCheckRedirectLoading(true);
            return;
        }

        if (user) {
            const { isSignUpRedirect } = checkAndRedirectSignUp();

            if (isSignUpRedirect) {
                return setIsCheckRedirectLoading(true);
            }

            if (isLoggedRedirect && userHomepage !== pathname) {
                router.push(searchParams.size ? `${userHomepage}?${searchParams}` : userHomepage);
                return setIsCheckRedirectLoading(true);
            }
        }

        setIsCheckRedirectLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedRedirect, launchCheckRedirect, pathname, router, searchParams, user, userHomepage]);

    return { isCheckRedirectLoading };
}

export default useCheckAndRedirect;
