import { usePathname, useRouter, useSearchParams } from 'next/navigation';

// Lib
import { SIGN_UP } from '@/lib/routes/privateRoutes';

// Utils
import useUser from '@/utils/hooks/swr/useUser';
import useUrlUserHomepage from '@/hooks/user/useUrlUserHomepage';
import { useSignUpStore } from '@/utils/zustand/providers/sign-up-provider';
import { getSignUpStepLocalStorage } from '@/utils/localStorage/signUpStepLocalStorage';

// Types
import type { UserType } from '@/types/userType';

function useSignUpCheck() {
    const { user } = useUser();
    const { userHomepage } = useUrlUserHomepage();

    const router = useRouter();
    const pathname = usePathname();
    const searchParams = useSearchParams();

    const { updateCurrentStep, currentStep } = useSignUpStore((state) => state);

    const redirectTo = (url: string) => {
        if (searchParams.size) {
            return router.push(`${url}?${searchParams}`);
        }

        return router.push(url);
    };

    const checkAndRedirectSignUp = () => {
        const userCurrentStep = user.sign_up_step.toUpperCase() as Uppercase<
            UserType['sign_up_step']
        >;
        const isEmailVerified = Boolean(user.email_verified_at);
        if (userCurrentStep !== 'SIGN_UP_DONE' || !isEmailVerified) {
            /*
             * If the current step is not set in the store, then set it from the local storage
             */
            if (!currentStep) {
                const signUpStep = getSignUpStepLocalStorage(user.id) ?? userCurrentStep;
                const signUpNewStep = !isEmailVerified ? 'USER_EMAIL_VERIFICATION' : signUpStep;

                updateCurrentStep(signUpNewStep, user.id);

                // Redirect to the new step
                if (pathname !== SIGN_UP[signUpNewStep]) {
                    redirectTo(SIGN_UP[signUpNewStep]);
                    return { isSignUpRedirect: true };
                }

                return { isSignUpRedirect: false };
            }

            if (pathname !== SIGN_UP[currentStep]) {
                if (currentStep === 'SIGN_UP_DONE') {
                    // Redirect to the user homepage
                    redirectTo(userHomepage);
                    return { isSignUpRedirect: true };
                }

                // Redirect to the current step page
                redirectTo(SIGN_UP[currentStep]);
                return { isSignUpRedirect: true };
            }
        }

        // If the user has completed the sign up process and is in the sign up funnel
        if (
            userCurrentStep === 'SIGN_UP_DONE' &&
            Object.values(SIGN_UP).find((url) => url === pathname)
        ) {
            // Redirect to the user homepage
            redirectTo(userHomepage);
            return { isSignUpRedirect: true };
        }

        return { isSignUpRedirect: false };
    };

    return { checkAndRedirectSignUp };
}

export default useSignUpCheck;
