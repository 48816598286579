// Utils
import useSocialProfiles from '@/utils/hooks/swr/useSocialProfiles';
import { getActiveProfileIdLocalStorage } from '@/utils/localStorage/profilesLocalStorage';
import { useProfilesStore } from '@/utils/zustand/providers/profiles-provider';

function useInitializeActiveProfile() {
    const { updateActiveSocialProfileId } = useProfilesStore((state) => state);

    // Swr Hooks
    const { activatedSocialProfiles } = useSocialProfiles({ revalidateIfStale: false });

    function initializeActiveProfile({ launchInitialize }: { launchInitialize: boolean }) {
        const activeProfileIdLocalStorage = getActiveProfileIdLocalStorage();

        if (activeProfileIdLocalStorage && launchInitialize) {
            const activeProfile = activatedSocialProfiles.find(
                (profile) => profile.id === activeProfileIdLocalStorage
            );

            if (activeProfile) {
                updateActiveSocialProfileId(activeProfileIdLocalStorage);
                return { hasInitializedActiveProfile: true };
            }
        }

        return { hasInitializedActiveProfile: false };
    }

    return { initializeActiveProfile };
}

export default useInitializeActiveProfile;
