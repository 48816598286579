import { useEffect, useState } from 'react';

// Hooks
import useSPGroups from '@/hooks/swr/group/useSPGroups';
import useSocialProfiles from '@/hooks/swr/useSocialProfiles';
import { useProfilesStore } from '@/utils/zustand/providers/profiles-provider';
import useInitializeActiveGroup from '@/utils/hooks/user/useCurrentSession/useInitializeProfilesStore/useInitializeActiveGroup';
import useInitializeMultiProfiles from '@/utils/hooks/user/useCurrentSession/useInitializeProfilesStore/useInitializeMultiProfiles';
import useInitializeActiveProfile from '@/utils/hooks/user/useCurrentSession/useInitializeProfilesStore/useInitializeActiveProfile';
import useUser from '@/utils/hooks/swr/useUser';

type useInitializeActiveProfilesProps = { isUserLoading: boolean };

function useInitializeProfilesStore({ isUserLoading }: useInitializeActiveProfilesProps) {
    const { manageProfiles } = useProfilesStore((state) => state);

    // Loader states
    const [isGroupLoading, setIsGroupLoading] = useState(true);
    const [isSocialProfilesLoading, setIsSocialProfilesLoading] = useState(true);

    // Swr Hooks
    const { user } = useUser();
    const { activatedSpGroups, isLoading: isLoadingSpGroups } = useSPGroups();
    const { activatedSocialProfiles, isLoading: isLoadingSocialProfiles } = useSocialProfiles();

    // Custom hooks
    const { initializeActiveGroup } = useInitializeActiveGroup();
    const { initializeMultiProfiles } = useInitializeMultiProfiles();
    const { initializeActiveProfile } = useInitializeActiveProfile();

    useEffect(() => {
        if (isUserLoading || isLoadingSocialProfiles || isLoadingSpGroups) {
            return;
        }

        /*
         * If there is no user, stop the loader
         */
        if (!user) {
            setIsSocialProfilesLoading(false);
            setIsGroupLoading(false);
            return;
        }

        /**
         * Initialize active group from local storage
         */
        const { hasInitializedGroup } = initializeActiveGroup();

        /**
         * Initialize active multi profiles from local storage
         */
        const { hasInitializedMultiProfiles } = initializeMultiProfiles();

        /**
         * Initialize active profile from local storage
         */
        const { hasInitializedActiveProfile } = initializeActiveProfile({
            launchInitialize: !hasInitializedGroup && !hasInitializedMultiProfiles
        });

        // If there is no active profile or group, set the first profile as active
        if (
            !hasInitializedGroup &&
            !hasInitializedActiveProfile &&
            !hasInitializedMultiProfiles &&
            activatedSocialProfiles.length
        ) {
            manageProfiles(activatedSocialProfiles);
        }

        setIsSocialProfilesLoading(false);
        setIsGroupLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        isUserLoading,
        isLoadingSocialProfiles,
        isLoadingSpGroups,
        activatedSocialProfiles,
        activatedSpGroups
    ]);

    return { isInitialProfilesLoading: isGroupLoading || isSocialProfilesLoading };
}

export default useInitializeProfilesStore;
