import { useParams } from 'next/navigation';

/**
 * Format a URL pathname with the given params.
 * For example formatUrlPathname('/dashboard/[team]', { team: 'nextjs' }) would return '/dashboard/nextjs'
 */
export function formatUrlPathname(url: string, params: ReturnType<typeof useParams>) {
    return url.replace(/\[([^\]]*)\]/g, (match, p1: string) => {
        const paramKeys = p1.startsWith('...') ? p1.slice(3) : p1;

        if (paramKeys in params) {
            const paramValues = params[paramKeys];
            if (Array.isArray(paramValues)) {
                return paramValues.join('/');
            }
            return paramValues;
        }

        return match;
    });
}

export function formatUrls(urls: string[], params: ReturnType<typeof useParams>) {
    return urls.map((url) => formatUrlPathname(url, params));
}

export function formatObjectWithUrlKeys<TEntry>(
    obj: Record<string, TEntry>,
    params: ReturnType<typeof useParams>
) {
    return Object.keys(obj).reduce(
        (acc, key) => {
            const formattedKey = formatUrlPathname(key, params);
            acc[formattedKey] = obj[key];
            return acc;
        },
        {} as Record<string, TEntry>
    );
}
