
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { useRouter } from 'next/router';
//Utils
import useCurrentSession from '@/utils/hooks/user/useCurrentSession';
import { SIGN_IN } from '@/routes/publicRoutes';
//Components
import LoaderPage from '@/components/base/Loader/LoaderPage';
export default function Homepage() {
    const router = useRouter();
    const { user, isLoading } = useCurrentSession({ isLoggedRedirect: true });
    if (!isLoading && !user) {
        router.push({
            pathname: SIGN_IN
        });
    }
    return <LoaderPage />;
}

    async function __Next_Translate__getStaticProps__193ba5c93f8__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__193ba5c93f8__ as getStaticProps }
  