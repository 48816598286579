// Utils
import useSocialProfiles from '@/utils/hooks/swr/useSocialProfiles';
import { useProfilesStore } from '@/utils/zustand/providers/profiles-provider';

// Types
import {
    getActiveMultiProfileIdsLocalStorage,
    getActiveProfileIdLocalStorage
} from '@/utils/localStorage/profilesLocalStorage';

function useInitializeMultiProfiles() {
    const { updateActiveMultiProfileIds, updateActiveSocialProfileId } = useProfilesStore(
        (state) => state
    );

    // Swr Hooks
    const { activatedSocialProfiles } = useSocialProfiles({ revalidateIfStale: false });

    function initializeMultiProfiles() {
        const activeProfileIdLocalStorage = getActiveProfileIdLocalStorage();
        const activeMultiProfileIdsLocalStorage = getActiveMultiProfileIdsLocalStorage();

        if (activeMultiProfileIdsLocalStorage) {
            const activeMultiProfileIds = activeMultiProfileIdsLocalStorage;
            const multiProfiles = activatedSocialProfiles?.filter((profile) =>
                activeMultiProfileIds.includes(profile.id)
            );

            if (multiProfiles?.length === 1) {
                // Update the active profile id in the store if there is only one profile remaining
                updateActiveSocialProfileId(multiProfiles[0].id);
                return { hasInitializedMultiProfiles: true };
            }

            if (multiProfiles?.length > 1) {
                /**
                 * If there is an active profile in the local storage:
                 * Check if the active profile from local storage is still active in the multi profiles
                 */
                if (activeProfileIdLocalStorage) {
                    const activeProfileId = activeProfileIdLocalStorage;
                    if (multiProfiles.find((profile) => profile.id === activeProfileId)) {
                        updateActiveSocialProfileId(activeProfileId);
                    }
                }

                // Use manageProfiles to update the multi profiles in the store + set the active profile
                updateActiveMultiProfileIds(multiProfiles.map((profile) => profile.id));
                return { hasInitializedMultiProfiles: true };
            }
        }

        return { hasInitializedMultiProfiles: false };
    }
    return { initializeMultiProfiles };
}

export default useInitializeMultiProfiles;
