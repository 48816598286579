// Utils
import useSPGroups from '@/hooks/swr/group/useSPGroups';
import {
    getActiveGroupIdLocalStorage,
    getActiveProfileIdLocalStorage
} from '@/utils/localStorage/profilesLocalStorage';
import { useProfilesStore } from '@/utils/zustand/providers/profiles-provider';

function useInitializeActiveGroup() {
    const { updateActiveGroupId, updateActiveSocialProfileId } = useProfilesStore((state) => state);

    // Swr Hooks
    const { activatedSpGroups } = useSPGroups({ revalidateIfStale: false });

    function initializeActiveGroup() {
        const activeProfileIdLocalStorage = getActiveProfileIdLocalStorage();
        const activeGroupIdLocalStorage = getActiveGroupIdLocalStorage();

        if (activeGroupIdLocalStorage) {
            const activeGroup = activatedSpGroups.find(
                (group) => group.id === activeGroupIdLocalStorage
            );

            if (activeGroup) {
                // Update the active group id in the store
                updateActiveGroupId(activeGroupIdLocalStorage);

                /**
                 * If there is an active profile in the local storage:
                 * Check if the active profile from local storage is still active in the group
                 */
                if (activeProfileIdLocalStorage) {
                    const groupHasActiveProfile = activeGroup?.social_profiles?.find(
                        (profile) => profile.id === activeProfileIdLocalStorage
                    );

                    if (groupHasActiveProfile) {
                        updateActiveSocialProfileId(activeProfileIdLocalStorage);
                    }
                }

                return { hasInitializedGroup: true };
            }
        }

        return { hasInitializedGroup: false };
    }
    return { initializeActiveGroup };
}

export default useInitializeActiveGroup;
